<template>
  <div id="pagina-404-page">
    <div id="pagina-404-container">
      <div id="pagina-404-container-titulo-oops">
        <div class="display-4 text-warning" style="text-align: center">
          <i class="fas fa-exclamation-circle"></i>
        </div>
        <div id="pagina-404-titulo-oops" class="text-warning">Oops!</div>
        <div id="pagina-404-titulo-msg"> A página que você está procurando não foi encontrada! </div>
        <div class="col-12 mt-3" style="text-align: center">
          <router-link class="btn btn-secondary" id="lmxta-pagina404-btn-telalogin" :to="{ name: 'NavegacaoLogin' }">
            <span style="float: left; margin-right: 10px">
              <i class="fas fa-arrow-alt-circle-left"></i>
            </span>
            Ir para a tela de Login
          </router-link>
        </div>
      </div>
    </div>

    <div id="pagina-404-page-container">
      <div id="pagina-404-container-nome-app">
        <div id="pagina-404-nome-app-linxmicrovix">Linx Microvix</div>
        <div id="pagina-404-nome-app-estoque">{{ appName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NavegacaoErroPagina404',

    computed: {
      appName() {
        return process.env.VUE_APP_NOME_APLICACAO;
      },
    },
  };
</script>

<style scoped>
  #pagina-404-page {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #pagina-404-container {
    flex-grow: 9;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  #pagina-404-container-titulo-oops {
    flex-grow: 1;
    text-align: right;
  }

  #pagina-404-titulo-oops {
    font-size: 4rem !important;
    font-weight: bolder;
    font-family: Dosis, sans-serif;
    color: #5b2e90;
    text-align: center;
    margin-top: -30px !important;
  }

  #pagina-404-container-titulo-msg {
    flex-grow: 1;
    text-align: left;
    text-align: center;
  }

  #pagina-404-titulo-msg {
    font-size: 1.5em !important;
    font-family: Dosis, sans-serif;
    color: #5b2e90;
    text-align: center;
  }

  #pagina-404-nome-app-linxmicrovix {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-size: 2rem;
    color: #ffb200;
  }

  #pagina-404-nome-app-estoque {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-weight: bold;
    font-size: 2rem;
    color: #5b2e90;
  }

  .btn {
    line-height: 1.5 !important;
    width: 300px;
  }

  @media only screen and (max-width: 600px) {
    .btn {
      display: block !important;
      width: 100% !important;
    }
  }
</style>
